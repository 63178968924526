import { Injectable } from "@angular/core";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../../environments/environment.dev";
import { getAuth, signInWithPopup } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  app = initializeApp(firebaseConfig);
  auth = getAuth(this.app);

  async getUserClaims() {
    return await this.auth.currentUser?.getIdTokenResult(true);
  }

  async signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(this.auth, provider);
  }

  //Can be removed on production deployment
  async getUserToken() {
    return await this.auth.currentUser?.getIdToken(true);
  }
}
