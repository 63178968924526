import { inject, Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
@Injectable({
  providedIn: "root",
})
export class SnackbarService {
  snackbar = inject(MatSnackBar);

  message(message: string, timeout: number = 3000) {
    this.snackbar.open(message, "Close", {
      duration: timeout,
    });
  }
}
